/*
 *	-- Libraries and Plugins --
 *	Scripts concatenated and placed into document <head>
 */


// import('dependencies/framework/modernizr-3.0.0-custom.min.js');
// import('dependencies/framework/twig.min.js');

// import('dependencies/libraries/jquery-3.1.1.min.js');
// import('dependencies/libraries/hammer.min.js');
// import('dependencies/libraries/moment.js');
// import('dependencies/libraries/lodash.js');

// import('dependencies/plugins/jquery.easing.min.js');
// import('dependencies/plugins/js.storage.min.js');
